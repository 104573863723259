<!--
  -  TTTech nerve-management-system
  -  Copyright(c) 2022. TTTech Industrial Automation AG.
  -
  -  ALL RIGHTS RESERVED.
  -
  -  Usage of this software, including source code, netlists, documentation,
  -  is subject to restrictions and conditions of the applicable license
  -  agreement with TTTech Industrial Automation AG or its affiliates.
  -
  -  All trademarks used are the property of their respective owners.
  -
  -  TTTech Industrial Automation AG and its affiliates do not assume any liability
  -  arising out of the application or use of any product described or shown
  -  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
  -  make changes, at any time, in order to improve reliability, function or
  -  design.
  -
  -  Contact Information:
  -  support@tttech-industrial.com
  -
  -  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
  -
  -->
<template>
  <v-tooltip bottom>
    <template #activator="{ on }">
      <v-img
        v-show="statuses.indexOf(tableItem._currentStatus) !== -1"
        :id="'iiotNodeInformationTypeStatus_' + tableItem.currentStatus"
        :src="`/img/${tableItem._currentStatus}.svg`"
        :max-height="tableItem.currentStatus === 'error' ? '14px' : '10px'"
        :max-width="tableItem.currentStatus === 'error' ? '14px' : '10px'"
        :class="tableItem.currentStatus === 'error' ? 'error-icon-class' : 'ml-4'"
        v-on="on"
      />
      <v-progress-circular
        v-show="statuses.indexOf(tableItem._currentStatus) === -1"
        :id="'iiotNodeInformationTypeStatus_' + tableItem.currentStatus"
        indeterminate
        color="primary"
        class="mb-1 margin-progress"
        size="15"
        width="2"
        v-on="on"
      />
    </template>
    <span>{{ $t(`nerveSimpleTable.${tableItem.currentStatus}`) }}</span>
  </v-tooltip>
</template>

<script>
export default {
  props: {
    tableItem: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      statuses: ['started', 'stopped', 'suspended', 'error', 'idle', 'removing_failed', 'partially_running'],
    };
  },
};
</script>
<style>
.error-icon-class {
  margin-left: 14px;
}
.margin-progress {
  margin-left: 15px;
}
</style>
